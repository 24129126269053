import { useEffect, useState } from 'react';
import { getLoginInfoFromCookie, makeAuthenticatedRequest } from './utils';
import { useLocation } from 'react-router-dom';

const GithubSetupRedirect = () => {
  // get code from the query string
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const [message, setMessage] = useState('Loading...');

  const connectGithub = async (loginData) => {
    let clientSystemId = loginData.workspaces[0].client_system_id;
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('installation_id');
    const url = `${API_BASE_URL}/api/configure/data_sources`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        'client_system_id': clientSystemId,
        'data_source': {
          'installation_id': code,
          'type': 'github',
        },
      }),
    };

    let failure_message = 'Failed to connect to GitHub. Please try again later.';

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok){
      let data = await response.json();
      if (data.error) {
        setMessage(failure_message);
      } else {
        setMessage('Successfully connected to GitHub. Relvy will now look for relevant code changes to reported incidents.');
      }
    } else {
      setMessage(failure_message);
    }
  }

  useEffect(() => {
    getLoginInfoFromCookie(
      connectGithub,
      () => {
        window.location.href = '/';
      }
    );
  }, []);

  return (
    <div class="container">
      <h4 class="text-center my-5">{message}</h4>
      <p class="text-center my-5">Please head back to <a href="/configure">Configure</a> to continue setting up your workspace.</p>
    </div>
    );
};

export default GithubSetupRedirect;

