import { useEffect, useState } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const HistogramMetricView = (props) => {

  const chartTitle = () => {
    if (!props.metricData) { return ''; }
    var title = props.metricData['metric_def']['name'];
    if (props.metricData['attributes']) {
      title += ' (' + JSON.stringify(props.metricData['attributes']) + ')';
    }
    return title;
  };

	const options = {
		plugins: {
			title: {
				display: true,
        text: chartTitle(),
			},
		},
		responsive: true,
		scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute'
        },
        stacked: true,
      },
			y: {
				stacked: true,
			},
		},
	};

  const labels = props.metricData ? props.metricData['points'].map((item) => {
    return new Date(item['end_timestamp']);
  } ) : [];

  const getOneBucketTimeseries = (tsData, bucketIndx, bucketKey) => {
    let primaryMetric = tsData.map((item) => {
      if ('histogram' in item) {
        return item['histogram']['bucket_counts'][bucketIndx];
      } else {
        return item['value'][bucketKey] > 0 ? item['value'][bucketKey] : 0;
      }
    }).reverse();

    // generate random color for the line.
    let backgroundColor = 'rgb(' + Math.floor(Math.random() * 256) + ', ' + Math.floor(Math.random() * 256) + ', ' + Math.floor(Math.random() * 256) + ')';
    return {
      label: bucketKey,
      data: primaryMetric,
      backgroundColor: backgroundColor,
    };
  }

  const getBucketKeys = (metricData) => {
    if (!metricData) { return []; }
    if (!metricData['points'] || metricData['points'].length === 0) { return []; }

    if ('histogram' in metricData['points'][0]) {
      var prevVal = '-inf';
      let bucket_boundaries = []
      for (var i = 0; i < metricData['points'][0]['histogram']['bucket_boundaries'].length; i++) {
        bucket_boundaries.push(prevVal + '-' + metricData['points'][0]['histogram']['bucket_boundaries'][i]);
        prevVal = metricData['points'][0]['histogram']['bucket_boundaries'][i];
      }
      bucket_boundaries.push(prevVal + '-inf');
      return bucket_boundaries;
    } else {
       // legacy format
      let retVal = Object.keys(metricData['points'][0]['value']);
      return retVal;
    }
  }

  const data = {
    labels,
    datasets: props.metricData ? getBucketKeys(props.metricData).map((bucketKey, bucketIndx) => {
      return getOneBucketTimeseries(props.metricData['points'], bucketIndx, bucketKey);
    }) : [],
  };

  if (props.metricData.points.length === 0) { return ''; }

  return <Bar options={options} data={data} />;
};


const LineMetricView = (props) => {

  const [lineData, setLineData] = useState(undefined);

  const getOneTimeseries = (tsData) => {

    let primaryMetric = tsData['points'].map((item) => {
      return {
        x: (new Date(item['end_timestamp'])),
        y: item['value']
      }
    }
    ).reverse();

    // generate random color for the line.
    let borderColor = 'rgb(' + Math.floor(Math.random() * 256) + ', ' + Math.floor(Math.random() * 256) + ', ' + Math.floor(Math.random() * 256) + ')';
    return {
      label: JSON.stringify(tsData.attributes),
      data: primaryMetric,
      borderColor: borderColor,
      backgroundColor: 'rgba(135,206,250, 0.5)',
    };
  };

  const processMetricData = (metricData) => {
    if (!metricData) { return; }
    if (metricData.length === 0) { return; }

    var dataExists = false;
    metricData.forEach((item) => {
      if (item['points'].length > 0) {
        dataExists = true;
      }
    });

    if (!dataExists) { return; }

    return {
      datasets: metricData.map(getOneTimeseries)
    }
  };

  useEffect(() => {
    setLineData(processMetricData(props.metricData));
  }, [props.metricData]);


  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: (props.metricData && props.metricData.length > 0 ? props.metricData[0]['metric_def']['name'] : ''),
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute'
        },
        ticks: {
          source: 'data'
        },
      }
    },
  };

  if (!lineData) { return ''; }

  return (
    <div>
    {lineData===undefined ? '' : <Line options={options} data={lineData} />}
  </div>
  );
};


const MetricView = (props) => {

  const [tsType, setTsType] = useState(undefined);
  const [hasData, setHasData] = useState(false);

  const processMetricData = (metricData) => {
    if (!metricData) { return; }
    if (metricData.length === 0) { return; }

    setTsType(metricData[0]['metric_def']['metric_type']);
    if (metricData[0]['points'].length > 0) {
      setHasData(true);
    }
  };

  useEffect(() => {
    processMetricData(props.metricData);
  }, [props.metricData]);

  const getAdditionalTitleComponents = () => {
    var retVal = "";
    if (props.stepReason.includes("Relaxing constraint")){
      retVal += " (Relaxing query filters)";
    }
    if (!hasData){
      if (props.stepReason.includes("Relaxing constraint")){
        retVal += " (Still no data)"
      } else {
        retVal += " (No data found)";
      }
    }
    return retVal;
  }

  // return ( <div>
  //   {props.stepTitle && <h6 class="my-3">{props.stepTitle}</h6>}
  //   {props.stepReason && <div>{props.stepReason}</div>}
  //   {props.query && 
  //   <div class="row my-3">
  //     <p class="text-muted">Query {props.query.url && <span>(<small><a href={props.query.url} rel='noreferrer' target='_blank'>View on {props.query.dataSource}</a></small>)</span>}</p>
  //     <pre class="my-3">{props.query.rawQuery}</pre>
  //   </div>}
  //   {props.metricData && props.metricData.length > 0 && <div>
  //   {tsType === 'histogram' ? <div>{props.metricData.map((eachMetric) => { return <HistogramMetricView metricData={eachMetric} />; })}</div> : <LineMetricView metricData={props.metricData} />}
  //   </div>}
  //   {props.stepPostscript && <div class="row my-4">
  //     <p><span>{props.stepPostscript}</span></p>
  //   </div>}
  // </div>
  // );
  return (
    <div className="my-4">
      {props.stepTitle && (
      <p><strong>Step {props.stepTitleComponents.stepIndx}: Analyzing </strong><span class="border border-primary rounded-pill text-center p-1">{props.stepTitleComponents.metric}</span><strong> from </strong><span class="border border-secondary rounded-pill text-center p-1">{props.stepTitleComponents.service}</span>{getAdditionalTitleComponents()}</p>
      )}

      {props.stepReason && (
        <p className="mb-3 text-muted" style={{ fontSize: '0.95rem' }}>{props.stepReason}</p>
      )}

      {props.query && (
        <div className="mb-4">
          <p className="text-muted mb-2" style={{ fontSize: '0.9rem' }}>
            Query&nbsp;({props.query.dataSource})
            {false && props.query.url && (
              <span className="ms-2">
                (<a href={props.query.url} rel="noreferrer" target="_blank" className="text-info">View on {props.query.dataSource}</a>)
              </span>
            )}
          </p>
          <pre className="bg-light p-3 rounded" style={{ fontSize: '0.85rem', color: '#333' }}>{props.query.rawQuery}</pre>
        </div>
      )}

      {hasData && (
        <div className="mb-4">
          <h6 className="mb-3 text-secondary">Metric Visualization</h6>
          {tsType === 'histogram' ? (
            props.metricData.map((eachMetric, index) => (
              <HistogramMetricView key={index} metricData={eachMetric} />
            ))
          ) : (
            <LineMetricView metricData={props.metricData} />
          )}
        </div>
      )}

      {props.stepPostscript && (
        <div className="mt-4">
          <p className="fst-italic text-muted" style={{ fontSize: '0.95rem' }}>{props.stepPostscript}</p>
        </div>
      )}
    </div>
  );
};

export default MetricView;
