import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoSpeedometer, IoDesktopSharp, IoSearchSharp, IoPulse, IoMenu, IoClose } from 'react-icons/io5';

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const iconSize = 24;

  const renderNavLink = (to, icon, text) => {
    const link = (
      <NavLink 
        to={to} 
        className={({ isActive }) => 
          `sidebar-link mb-2 p-2 ${isActive ? 'active' : ''} ${!isExpanded ? 'align-self-center' : ''}`
        }
        style={({ isActive }) => ({
          color: isActive ? '#5935c9' : '#6c757d',
          textDecoration: 'none',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: isExpanded ? '24px' : '16px',
          whiteSpace: 'nowrap',
          gap: '12px'
        })}
      >
        {icon}
        {isExpanded && <span>{text}</span>}
      </NavLink>
    );

    return !isExpanded ? (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip>{text}</Tooltip>}
      >
        {link}
      </OverlayTrigger>
    ) : link;
  };

  return (
    <div className="sidebar bg-light" style={{ 
      width: isExpanded ? '200px' : '64px', 
      height: 'calc(100vh - 64px)',
      position: 'fixed', 
      left: 0, 
      top: '64px',
      zIndex: 100,
      transition: 'width 0.2s ease-in-out',
      borderRight: '1px solid #dee2e6'
    }}>
      <div className="d-flex flex-column py-3">
        <button
          className="align-self-center bg-transparent border-0 mb-3"
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ color: '#6c757d' }}
        >
          {isExpanded ? <IoClose size={28} /> : <IoMenu size={28} />}
        </button>

        {renderNavLink(
          "/dashboard",
          <IoSpeedometer size={iconSize} />,
          "Investigations"
        )}

        {renderNavLink(
          "/monitor",
          <IoDesktopSharp size={iconSize} />,
          "Auto Monitor"
        )}
      </div>
    </div>
  );
}

export default Sidebar; 