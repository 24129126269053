import { useEffect, useState } from 'react'
import { Form, Button, Modal, InputGroup, Card, Badge } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';

import { formatDateTime } from './utils';

import { IoAddCircle, IoRefresh, IoSearch } from 'react-icons/io5';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

import Sidebar from '../components/Sidebar';
import IssueDetailsPage from '../components/issue_details';
import LoginModule from '../components/glogin';

import '../components/demo.css';
import {getLoginInfoFromCookie, makeAuthenticatedRequest} from './utils';

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CreateNewIssueView = (props) => {
  const [issueSummary, setIssueSummary] = useState('');

  return (
    <div className="m-3">
      <h3>Describe the issue you are seeing</h3>
      <Form onSubmit={(e) => { e.preventDefault(); props.onSubmit(issueSummary); }}>
        <div className="d-flex flex-column">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Describe the issue (e.g., users are unable to sign in)"
            value={issueSummary}
            onChange={(e) => setIssueSummary(e.target.value)}
            className="mb-2"
          />
          <Button
            variant="success"
            type="submit"
            className="align-self-end"
          >
            Create
          </Button>
        </div>
      </Form>
    </div>
  );
}

const ListView = (props) => {
  return (
    <div className="d-flex flex-column gap-1">
      {props.items.map((item, index) => (
        <ListItemView 
          key={index} 
          item={item} 
          indx={index} 
          selectedIndx={props.selectedIssue} 
          selectHandler={props.selectHandler} 
        />
      ))}
    </div>
  );
}

const ListItemView = (props) => {
  const getIssueSummary = () => {
    return props.item.summary;
  }

  const isSelected = props.selectedIndx === props.indx;

  return (
    <Card 
      onClick={() => { props.selectHandler(props.item.id) }}
      className={`${isSelected ? 'border-primary' : ''}`}
      style={{ 
        cursor: isSelected ? "default" : "pointer",
        transition: 'all 0.2s ease-in-out'
      }}
    >
      <Card.Body className="py-1 px-3">
        <div className="d-flex flex-column">
          <span 
            className={`fw-medium ${isSelected ? 'text-primary' : ''}`}
            style={{ fontSize: '0.9rem' }}
          >
            {getIssueSummary().substring(0, 80)}
            {getIssueSummary().length > 80 ? '...' : ''}
          </span>
          <small className="text-muted" style={{ fontSize: '0.7rem' }}>
            {formatDateTime(props.item.created)}
          </small>
        </div>
      </Card.Body>
    </Card>
  );
}

const DetailView = (props) => {
  return (
    <div>
      <IssueDetailsPage item={props.item} refreshCallback={props.refreshCallback} selectIssueFromIdHandler={props.selectIssueFromIdHandler} />
    </div>
   );
}

const InsufficientCreditsModal = ({ show, onHide }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Insufficient Credits</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your organization does not have enough credits to create a new issue. Please get in touch with us to add more credits.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          className="btn custom-btn"
          onClick={() => {
            window.open("https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij", "_blank");
            onHide();
          }}
        >
          Contact Us
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Container = () => {
  const [loginState, setLoginState] = useState(null);

  const [clientSystemId, setClientSystemId] = useState(null);
  const [issues, setIssues] = useState([])
  const [selectedIssue, setSelectedIssue] = useState(null) // null means creating new issue
  const [userCredits, setUserCredits] = useState(0.0);
  const [showCredits, setShowCredits] = useState(true);
  const [showInsufficientCreditsModal, setShowInsufficientCreditsModal] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [cSearchParams] = useSearchParams();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const fetchIssues = (setSelectedIssueNull = true) => {
    if (!clientSystemId) {
      return;
    }

    return makeAuthenticatedRequest(`/api/dashboard/v2?client_system_id=${clientSystemId}`)
      .then(response => response.json())
      .then(data => {
        setIssues(data['issues']);
        //setUserCredits(data['user_credits']);
        if (setSelectedIssueNull) {
          setSelectedIssue(null);
        }
        return data['issues'];
      });
  }

  const handleIssueSelect = (issueId) => {
    var index = null;
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].id === issueId) {
        index = i;
        break;
      }
    }
    setSelectedIssue(index);
    const selectedIssueId = issues[index]?.id;
    if (selectedIssueId) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('issue_id', selectedIssueId.toString());
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  }

  const getFilteredIssues = () => {
    if (!searchTerm) {
      return issues;
    }
    if (searchTerm.length  === 0) {
      return issues;
    }

    let filteredIssues = []
    for (let i = 0; i < issues.length; i++) {
      if (issues[i].summary.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredIssues.push(issues[i])
      } else if (issues[i].description.toLowerCase().includes(searchTerm.toLowerCase())) {
        filteredIssues.push(issues[i])
      } else if (i === selectedIssue) {
        filteredIssues.push(issues[i])
      }
    }
    return filteredIssues;
  }

  const selectIssueFromId = (issueId) => {
    handleIssueSelect(issueId);
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const issueId = searchParams.get('issue_id');
    const newOrgId = searchParams.get('org_id');

    if (newOrgId) {
      setOrgId(newOrgId);
    }

    const fetchAndSetIssues = async () => {
      try {
        const fetchedIssues = await fetchIssues(false);
        if (Array.isArray(fetchedIssues)) {
          if (issueId) {
            const numericIssueId = parseInt(issueId, 10);
            const issueIndex = fetchedIssues.findIndex(issue => issue.id === numericIssueId);
            if (issueIndex !== -1) {
              setSelectedIssue(issueIndex);
            } else {
              console.log(`Issue with id ${numericIssueId} not found`);
            }
          } 
          // else {
          //   // If no issue_id in URL, select the first issue by default
          //   setSelectedIssue(fetchedIssues.length > 0 ? 0 : null);
          // }
        }
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    };

    fetchAndSetIssues();
  }, [clientSystemId, location.search]);

  const onCancelCreateNew = () => {
    setSelectedIssue(issues.length > 0 ? 0 : null);
  }

  const onSubmitNew = (issueSummary) => {
    makeAuthenticatedRequest(`/api/dashboard/v2/issues`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        client_system_id: clientSystemId,
        summary: issueSummary
      })
    })
    .then(response => {
      if (!response.ok) {
        return response.json().then(data => {
          if (data.error_code === 'INSUFFICIENT_CREDITS') {
            setShowInsufficientCreditsModal(true);
            throw new Error('Insufficient credits');
          }
          throw new Error(data.message || 'An error occurred');
        });
      }
      return response;
    })
    .then(() => fetchIssues())
    .then((updatedIssues) => {
      setSelectedIssue(0);  // Select the newest issue
    })
    .catch(error => {
      if (error.message !== 'Insufficient credits') {
        console.error('Error creating new issue:', error);
        // Handle other errors here
      }
    });
  }

  const loginCallback = (data) => {
    if (data.workspaces.length > 0) {
      const queryClientSystemId = cSearchParams.get('client_system_id');
      setClientSystemId(queryClientSystemId || data.workspaces[0].client_system_id);
      setLoginState('logged_in');
      if (!data.organization.in_trial){
        setUserCredits(data.organization.credits);
      } else {
        setShowCredits(false);
      }
    } else {
      console.error('No workspaces found for the user');
      setLoginState('not_logged_in');
      window.location.href = '/';
    }
  }

  const loginFailureCallback = () => {
    window.location.href = '/';
    setLoginState('not_logged_in');
  }  

  useEffect(() => {
    if (loginState === null) {
      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }
  }, [])

  if (!loginState) {
    return "";
  }

  if (!clientSystemId && loginState === 'not_logged_in'){
    return <LoginModule onSuccessCallback={loginCallback} />;
  }

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="container-fluid" style={{ 
        marginLeft: '64px',
        maxWidth: 'calc(100vw - 64px)',
        transition: 'margin-left 0.2s ease-in-out'
      }}>
        <div className="row">
          {/* Left panel - Issue List */}
          <div className="col-md-3 position-sticky" style={{ 
            top: '64px',
            height: 'calc(100vh - 64px)',
            overflowY: 'auto',
            paddingTop: '1rem'
          }}>
            <div className="card">
              <div className="card-body">
                {showCredits && <><div className="mb-3">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="fs-6">Credits Left:</span>
                    <span className="fs-5 fw-bold">${userCredits >= 0 ? userCredits.toFixed(2) : "--"}</span>
                  </div>
                  <div className="progress" style={{height: "10px"}}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: `${Math.max(0, (userCredits / 200) * 100)}%`,
                        backgroundColor: '#5935c9'
                      }}
                      aria-valuenow={userCredits}
                      aria-valuemin="0"
                      aria-valuemax="200"
                    ></div>
                  </div>
                </div>
                <hr /></>}
                <div className="d-flex justify-content-between align-items-center mb-2 bg-light p-2">
                  <h5 className="mb-0">Investigations</h5>
                  <div className="d-flex justify-content-between align-items-center">
                    <Button 
                      variant="link" 
                      className="p-0 d-flex align-items-center justify-content-center" 
                      style={{ width: '32px', height: '32px' }}
                      onClick={() => toggleSearch()}
                    >
                      <IoSearch size={20} color="grey" />
                    </Button>
                    <Button 
                      variant="link" 
                      className="p-0 d-flex align-items-center justify-content-center" 
                      style={{ width: '32px', height: '32px' }}
                      onClick={() => fetchIssues(false)}
                    >
                      <IoRefresh size={20} color="grey" />
                    </Button>
                    <Button 
                      variant="link" 
                      className="p-0 d-flex align-items-center justify-content-center" 
                      style={{ width: '32px', height: '32px' }}
                      onClick={() => setSelectedIssue(null)}
                    >
                      <IoAddCircle size={28} color="green" />
                    </Button>
                  </div>
                </div>
                {showSearch && <div>
                  <InputGroup>
                    <InputGroup.Text><Search /></InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </InputGroup>
                </div>}
                <ListView items={getFilteredIssues()} selectedIssue={selectedIssue} selectHandler={handleIssueSelect}/>
              </div>
            </div>
          </div>
          
          {/* Right panel - Issue Details */}
          <div className="col-md-9" style={{ 
            paddingTop: '1rem',
            maxWidth: '100%',
            overflowX: 'hidden'
          }}>
            {selectedIssue === null ? (
              <CreateNewIssueView onCancel={onCancelCreateNew} onSubmit={onSubmitNew} />
            ) : (
              issues[selectedIssue] !== undefined && (
                <DetailView item={issues[selectedIssue]} refreshCallback={fetchIssues} selectIssueFromIdHandler={selectIssueFromId} />
              )
            )}
          </div>
        </div>
        <InsufficientCreditsModal
          show={showInsufficientCreditsModal}
          onHide={() => setShowInsufficientCreditsModal(false)}
        />
      </div>
    </div>
  );
}

function DashboardV2() {
  return (
    <Container />
  );
}

export default DashboardV2;
