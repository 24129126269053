import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const VerifyEmail = () => {
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (!token) {
      return;
    }

    fetch(`${API_BASE_URL}/api/onboarding/verify_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 'token': token })
    })
      .then((response) => response.json())
      .then((data) => {
        setMessage("Thank you! Your email has been verified.");
      })
      .catch((error) => {
        setMessage("Failed to verify email. Please try again.");
      });
  }, []);


  return (
    <div className="container d-flex flex-column" style={{ minHeight: '90vh' }}>
      <div className="row flex-grow-1 align-items-center justify-content-center">
        <div className="col-md-4 col-sm-10 text-center">
          <img
            src="/RelvyAI_JustLogo.png"
            alt="Relvy Logo"
            className="img-fluid mb-4"
            style={{ maxWidth: '100px' }}
          />
          <h3 className="mb-3">Welcome to Relvy</h3>
          <div className="card p-3 shadow-sm mb-4">
            <p className="mb-3" style={{ fontSize: '1rem' }}>
              {message}
            </p>
          </div>
        </div>
      </div>
    </div>);
}

export default VerifyEmail;
