import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Table, Toast, InputGroup, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import withAuth from '../components/withAuth.js';
import '../components/settings.css';
import '../components/demo.css';
import { Search, PlusLg } from 'react-bootstrap-icons';
import { IoCopy } from 'react-icons/io5';

import { makeAuthenticatedRequest } from './utils';


function OrganizationSetup({ onNext, userData }) {
  const [orgName, setOrgName] = useState(userData.organization?.name || '');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  const handleNext = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await makeAuthenticatedRequest(`/api/organization`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: orgName,
          user_id: userData.user.id
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      setInviteLink(responseData.invitation_url);
      setShowModal(true);
    } catch (error) {
      console.error('Error creating organization:', error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalNext = () => {
    setShowModal(false);
    navigate('/configure');
  };

  return (
    <div style={{ width: '75%' }}>
      <h3 className="mb-4">Organization</h3>
      <hr className="my-4" width="75%" />
      <Form onSubmit={handleNext} style={{ width: '75%' }}>
        <Form.Group className="mb-4">
          <Form.Label>Organization name</Form.Label>
          <Form.Text className="text-muted d-block mb-2">
            Setup an organization for yourself. You can invite your team to collaborate.
          </Form.Text>
          <Col md={6}>
            <Form.Control
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              required
            />
          </Col>
        </Form.Group>

        <div className="d-flex justify-content-end mt-4">
          <Button variant="primary" type="submit" className="custom-btn" disabled={isLoading || !orgName.trim()}>
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Loading...
              </>
            ) : (
              'Next'
            )}
          </Button>
        </div>
      </Form>

      <Modal 
        show={showModal} 
        onHide={() => {
          setShowModal(false);
          handleModalNext();
        }}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img src="/RelvyAI_JustLogo.png" alt="Relvy Logo" style={{ width: '30px', marginRight: '10px' }} />
            Invite Your Team!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ fontFamily: 'Arial, sans-serif', fontSize: '0.9rem', fontWeight: '500', color: '#333', lineHeight: '1.5' }}>
            You can collaborate with your team and invite them using this link.
          </p>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              value={inviteLink}
              readOnly
              style={{ fontSize: '0.8rem' }}
            />
            <Button variant="secondary" onClick={() => {
              navigator.clipboard.writeText(inviteLink);
              setShowToast(true);
            }}>
              <IoCopy />
            </Button>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="custom-btn" onClick={handleModalNext}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <Toast
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
        }}
      >
        <Toast.Body>Invite link copied to clipboard!</Toast.Body>
      </Toast>
    </div>
  );
}

function IntegrationsSetup({ onNext, onBack }) {
  const handleNext = (e) => {
    e.preventDefault();
    // Save integrations data
    onNext();
  };

  return (
    <div>
      <h3 className="mb-4">Integrations</h3>
      <hr className="my-4" width="75%" />
      <Form onSubmit={handleNext}>
        {/* Add integrations setup components here */}
        <div className="d-flex justify-content-between">
          <Button variant="secondary" onClick={onBack}>Back</Button>
          <Button variant="primary" type="submit">Get Started</Button>
        </div>
      </Form>
    </div>
  );
}

function Setup({ userData }) {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();

  const handleNext = () => {
    if (step < 2) {
      setStep(step + 1);
    } else {
      // Setup complete, navigate to dashboard
      navigate('/dashboard');
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }} className="main-content">
          <Container className="py-4">
            {step === 1 && <OrganizationSetup onNext={handleNext} userData={userData} />}
            {step === 2 && <IntegrationsSetup onNext={handleNext} />}
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default withAuth(Setup);
