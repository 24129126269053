import { IoTrashOutline } from 'react-icons/io5';

import '../css/service_item.css';

const ServiceItem = ({ service, index, getDatasourceNameFromId, handleRemoveService }) => {
    const datasourceName = getDatasourceNameFromId(service['data_source_id']);
  
    return (
      <div className="service-item p-4 mb-3 border rounded shadow-sm">
        <div className="row align-items-center">
          {/* Service Name and Source */}
          <div className="col-md-3">
            <h6 className="mb-0">
              {service.name}
              <span className="badge bg-light text-secondary ms-2">{datasourceName}</span>
            </h6>
          </div>

          {/* Logs Section */}
          <div className="col-md-4">
            {datasourceName === 'gcp' && (
              <pre className="logs-preview p-2 bg-light rounded small">{service.logs.query}</pre>
            )}
            {datasourceName === 'aws' && (
              <div className="logs-container">
                <div className="log-group mb-2">
                  <label className="text-muted small">Log Group Name</label>
                  <div className="fw-bold">{service.logs['log_group_name']}</div>
                </div>
                <pre className="logs-preview p-2 bg-light rounded small">{service.logs.query}</pre>
              </div>
            )}
            {datasourceName === 'datadog' && (
              <pre className="logs-preview p-2 bg-light rounded small">{service.logs.query}</pre>
            )}
          </div>

          {/* Metrics Section */}
          <div className="col-md-4">
            {service.metrics && service.metrics.length > 0 && (
              <details className="metrics-details">
                <summary className="metrics-summary btn btn-outline-secondary btn-sm">
                  {service.metrics.length} metrics from{' '}
                  <span className="fw-bold">
                    {datasourceName === 'aws' 
                      ? service.metrics[0].namespace 
                      : service.metrics[0].name.split('.')[0]}
                  </span>
                </summary>
                
                <div className="metrics-content mt-3">
                  <div className="mb-3">
                    <label className="text-muted small">
                      {datasourceName === 'aws' ? 'Namespace' : 'Product/Prefix'}
                    </label>
                    <div className="fw-bold">
                      {datasourceName === 'aws'
                        ? service.metrics[0].namespace
                        : service.metrics[0].name.split('.')[0]}
                    </div>
                  </div>
                  
                  <div>
                    <label className="text-muted small">Metrics</label>
                    <div className="metrics-list">
                      {service.metrics.map((metric) => (
                        <span key={metric.name} className="badge bg-light text-secondary me-2 mb-2">
                          {datasourceName === 'aws'
                            ? metric.name
                            : metric.name.split('/').pop()}
                        </span>
                      )).reduce((prev, curr) => [prev, ' ', curr])}
                    </div>
                  </div>
                </div>
              </details>
            )}
          </div>

          {/* Delete Button */}
          <div className="col-md-1 text-end">
            <button 
              onClick={() => handleRemoveService(index)} 
              className="btn btn-outline-danger btn-sm"
              title="Remove Service"
            >
              <IoTrashOutline />
            </button>
          </div>
        </div>
      </div>
    );
  };

export default ServiceItem;