import { useEffect, useState } from 'react';
import { Col, Form, Button } from 'react-bootstrap';

const ResetPassword = (props) => {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let token = queryParams.get('token');
    let email = queryParams.get('email');
    if (!token || !email) {
      setMessage('Invalid reset password link');
      setStatus('error');
      return;
    }
    setToken(queryParams.get('token'));
    setEmail(queryParams.get('email'));
  }, []);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let response = await fetch(`${API_BASE_URL}/api/onboarding/reset_password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'email': email,
          'token': token,
          'password': password,
        }),
      });
      if (response.ok){
        setIsLoading(false);
        setMessage('Password reset successfully.');
        setStatus('success');
      } else {
        setMessage('Password reset failed.');
        setStatus('failure');
      }
    } catch (error) {
      setMessage('Password reset failed.');
      setStatus('failure');
    }
  }

  return (
    <div className="container d-flex flex-column" style={{ minHeight: '90vh' }}>
      <div className="row flex-grow-1 align-items-center justify-content-center">
        <div className="col-md-4 col-sm-10 text-center">
        <img src="/RelvyAI_JustLogo.png" alt="Relvy Logo" className="img-fluid mb-4" style={{ maxWidth: '100px' }} />
        <h3 className="mb-3">Welcome to Relvy</h3>
          <div className="card p-3 shadow-sm mb-4">
            {status === null && <>
              <Form onSubmit={handleResetPassword} style={{ width: '75%' }}>
                <Form.Group className="mb-4">
                  <Col md={12} className="text-start">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={true}
                    required
                    />
                  </Col>
                  <Col md={12} className="text-start">
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      />
                  </Col>
                </Form.Group>
                <div className="d-flex justify-content-start mt-4">
                  <Button variant="primary" type="submit" className="custom-btn" disabled={isLoading}>
                    {isLoading ? (
                      <>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Loading...
                      </>
                    ) : (
                      'Reset Password'
                    )}
                  </Button>
                </div>
              </Form>
          </>}
          {message && <div className="alert alert-info mt-3">{message}</div>}
              <div className="row mt-3">
                <div className="text-start">
                  <div><button className="btn btn-link btn-sm px-0" onClick={() => window.location.href='/'}>{status !== 'success' ? 'Go back home' : 'Sign in'}</button></div>
                </div>
              </div>
          </div>
          <hr className="w-100 mx-auto mb-3" />
          <div className="explore-section">
            <h5 className="mb-3">Explore Relvy</h5>
            <div className="d-grid gap-2 col-8 mx-auto">
              <a href="https://share.hsforms.com/1R29gPNRURmKe6vLR_0Q9vQrhaij" className="btn btn-sm btn-outline-primary" target="_blank" rel="noreferrer">
                Self-host Relvy (Enterprise)
              </a>
              <a href="https://sandbox.relvy.ai" className="btn btn-sm btn-outline-secondary" target="_blank" rel="noreferrer">
                Try Sandbox Demo
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="mt-auto py-3">
        <div className="text-center">
          <small>
            <a href="https://www.relvy.ai/privacy-policy" className="text-muted me-2" target="_blank" rel="noreferrer">Privacy Policy</a>
            |
            <a href="https://www.relvy.ai/relvy-tos" className="text-muted ms-2" target="_blank" rel="noreferrer">Terms of Service</a>
          </small>
        </div>
      </footer>
    </div>
  );
};

export default ResetPassword;
