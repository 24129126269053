import { useEffect, useState } from 'react';
import { getLoginInfoFromCookie, makeAuthenticatedRequest } from './utils';
import { useLocation } from 'react-router-dom';

const SlackRedirect = () => {
  // get code from the query string
  const location = useLocation();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

  const [message, setMessage] = useState('Loading...');

  const connectSlack = async (loginData) => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const url = `${API_BASE_URL}/api/slack/auth?code=${code}`;

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    };

    let failure_message = 'Failed to connect to Slack. Please try again later.';

    let response = await makeAuthenticatedRequest(url, options);
    if (response.ok){
      let data = await response.json();
      if (data.error) {
        setMessage(failure_message);
      } else {
        setMessage('Successfully connected to Slack. Invite @RelvyAI to your oncall slack channel and start using Relvy!');
      }
    } else {
      setMessage(failure_message);
    }
  }

  useEffect(() => {
    getLoginInfoFromCookie(
      connectSlack,
      () => {
        window.location.href = '/';
      }
    );
  }, []);

  return (
    <div class="container">
      <h4 class="text-center my-5">{message}</h4>
    </div>
    );
};

export default SlackRedirect;
