import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { AlertTriangle, ArrowRight } from 'lucide-react';
import { makeAuthenticatedRequest } from '../../routes/utils';
import { formatDateTime } from '../../routes/utils';
const AlertsSummary = ({ clientSystemId, serviceName, onAlertSelect }) => {
  const [summaryData, setSummaryData] = useState(null);
  const [error, setError] = useState(null);
  const [hasNoSummary, setHasNoSummary] = useState(false);

  const fetchSummaryData = async () => {
    try {
      const params = new URLSearchParams({
        client_system_id: clientSystemId,
        ...(serviceName && { service_name: serviceName })
      });

      const response = await makeAuthenticatedRequest(`/api/auto_monitor/summary_snapshot?${params}`);
      
      if (response.status === 404) {
        setHasNoSummary(true);
        return;
      }
      
      if (!response.ok) throw new Error('Failed to fetch summary data');
      
      const data = await response.json();
      setSummaryData({
        critical_insights: data.snapshot.summary.insights.map(insight => ({
          text: insight.text,
          alert_ids: insight.alert_list
        })),
        status: data.snapshot.summary.status,
        last_updated: formatDateTime(data.created_at)
      });
      setHasNoSummary(false);
    } catch (err) {
      setError(err.message);
    }
  };

  useEffect(() => {
    fetchSummaryData();
    const interval = setInterval(fetchSummaryData, 30000);
    
    return () => clearInterval(interval);
  }, [clientSystemId, serviceName]);

  if (error) return <div className="text-danger">Error: {error}</div>;
  if (hasNoSummary) return null;
  if (!summaryData) return <div>Loading...</div>;

  return (
    <Card className="mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5 className="mb-0">System Health Summary</h5>
          <small className="text-muted">Updated {summaryData.last_updated}</small>
        </div>

        <div className="mb-4">
          {/* <div className="d-flex align-items-center gap-2 mb-3">
            <AlertTriangle size={20} className="text-warning" />
            <span className="text-warning fw-semibold">
              System Status: {summaryData.status.toUpperCase()}
            </span>
          </div> */}
          
          {/* <h6 className="text-muted mb-3">Critical Points to Note:</h6> */}
          <div className="d-flex flex-column gap-2">
            {summaryData.critical_insights.map((insight, index) => (
              <div 
                key={index} 
                className="d-flex align-items-center gap-2 ps-2"
                style={{ borderLeft: '3px solid #dee2e6' }}
              >
                <span>{insight.text}</span>
                <div className="ms-2">
                  {insight.alert_ids.map((alertId, idx) => (
                    <button
                      key={alertId}
                      onClick={() => onAlertSelect(alertId)}
                      className="badge bg-light text-primary border border-primary-subtle mx-1 px-2 py-1"
                      style={{ 
                        cursor: 'pointer',
                        transition: 'all 0.2s ease',
                        fontSize: '0.85rem',
                        textDecoration: 'none'
                      }}
                      onMouseOver={(e) => e.target.style.backgroundColor = '#e8f0fe'}
                      onMouseOut={(e) => e.target.style.backgroundColor = '#fff'}
                    >
                      #{alertId}
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AlertsSummary; 