import React, { useState, useEffect } from 'react';
import { getLoginInfoFromCookie } from '../routes/utils';
import AuthComponent from './auth_component.js';

const withAuth = (WrappedComponent) => {
  return function WithAuthComponent(props) {
    const [loginState, setLoginState] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      const loginCallback = (data) => {
        setUserData(data);
        setLoginState('logged_in');
      };

      const loginFailureCallback = () => {
        window.location.href = '/';
        setLoginState('not_logged_in');
      };

      getLoginInfoFromCookie(loginCallback, loginFailureCallback);
    }, []);

    if (loginState === null) {
      return ''; // Or a loading indicator
    }

    if (loginState === 'not_logged_in') {
      return (
        <AuthComponent
          onLoginSuccess={(data) => {
            setUserData(data);
            setLoginState('logged_in');
          }}
        />
      );
    }

    return <WrappedComponent {...props} userData={userData} />;
  };
};

export default withAuth;
