import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom'

const ExpertRunbookView = (props) => {
  return (
    <div id="expert-steps" class="m-3">
      <h4 class="my-3">Your Debug Steps</h4>
      {props.item['expert_steps'].length === 0 && <div class="row"><span class="text-center"><Spinner animation="border" role="status" variant="purple"></Spinner></span></div>}
      { props.item['expert_steps'].map((item, indx) => {
        return (
        <div class="row">
          <p><span>Step {item['step_index']}: </span><span class="text-muted">{item['event_payload']['humanized_step']}</span>(<a target="_blank" rel="noreferrer" href={item['event_payload']['url']}>link</a>)</p>
        </div>
        );})}
    </div>
   );
};

const ExpertSteps = (props) => {
  console.log(props);
  var timeoutId = null;
  var numTries = 0;
  let maxTries = 10;

  const [steps, setSteps] = useState([]);
  let { issueId } = useParams();
  const fetchSteps = () => {
    fetch("/api/alerts/" + issueId + "/expert_steps")
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.length === 0 && numTries < maxTries) {
          numTries += 1;
          timeoutId = setTimeout(fetchSteps, 1000);
        }
        setSteps(data);
      })
  }

  useEffect(() => {
    fetchSteps();

    return () => {
      clearTimeout(timeoutId);
    }
  }, []);

  return (
    <div class="container">
      <ExpertRunbookView item={{'expert_steps': steps}} />
    </div>
  );
};
export default ExpertSteps;
